import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const HeaderWrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9;

  ${({ isHidden }) => isHidden && css`pointer-events: none;`};
`;

HeaderWrapper.defaultProps = {
  isHidden: false,
};

HeaderWrapper.propTypes = {
  isHidden: PropTypes.bool,
};

export default HeaderWrapper;
