import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Box from '@nubank/nuds-web/components/Box/Box';
import { createPortal } from 'react-dom';

import useWindowSize from '@nubank/www-latam-commons/utils/hooks/useWindowSize';

import ApplyPopup from '../ApplyPopUp/ApplyPopUp';
import { popUpExperimentEvent } from '../../screens/Registration/tracking';
import { useSiteContext } from '../SiteContext/SiteContext';

import FooterNavigation from './FooterNavigation/FooterNavigation';
import FooterBottom from './FooterBottom/FooterBottom';

// Pop Up Experiment
const POP_UP_EXPERIMENT_ID = '343892';
const POP_UP_CONTROL_VARIANT = '45722';
const POP_UP_1_MIN_EXPERIMENT_VARIANT = '45723';

const Footer = ({ hideFooterNavigation }) => {
  const {
    displayPopUpContext,
    updateDisplayPopUpContext,
    registrationFlowEvents,
  } = useSiteContext();
  const { height } = useWindowSize();
  const ref = useRef();
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  let showPopUp = true;

  const openPopUpTimer = waitingTime => {
    // Condition to display the pop up
    // We havent displayed the pop up yet, the user stayed on the page for 1 or 2 minutes
    // the user hasn't started the application flow
    const timer = setTimeout(() => {
      if (showPopUp && displayPopUpContext && !registrationFlowEvents.contactData) {
        showPopUp = false;
        popUpExperimentEvent('displayed');
        popUpExperimentEvent('timer');
        setIsPopUpOpen(true);
      }
    }, waitingTime);
    return () => clearTimeout(timer);
  };

  const handleScroll = () => {
    const yPosition = window.scrollY;
    const triggerPopUpByScroll = yPosition > ref?.current?.offsetTop - height;

    // Condition to display the pop up
    // We havent displayed the pop up yet, the user scrolled to the footer
    // the user hasn't started the application flow
    if (showPopUp && displayPopUpContext && triggerPopUpByScroll
      && !registrationFlowEvents.contactData) {
      showPopUp = false;
      popUpExperimentEvent('displayed');
      popUpExperimentEvent('scroll');
      setIsPopUpOpen(true);
    }
  };

  const initializePopUp = waitingTime => {
    openPopUpTimer(waitingTime);
    handleScroll();
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  };

  // Check every .5s if we have an active variant for 10s
  const checkPopUpVariant = () => {
    let count = 0;
    const timeInterval = setInterval(() => {
      count += 0.5;
      let variant = '';

      const figPiiInfo = window?.FIGPII?.experimentManager?.getAllChosenVariations?.();

      if (figPiiInfo) {
        if (Object.keys(figPiiInfo).length >= 1) {
          variant = POP_UP_EXPERIMENT_ID in figPiiInfo ? figPiiInfo[POP_UP_EXPERIMENT_ID] : '';

          // The pop up xp is active
          if (variant && variant !== POP_UP_CONTROL_VARIANT) {
            const waitingTime = variant === POP_UP_1_MIN_EXPERIMENT_VARIANT ? 60000 : 120000;
            initializePopUp(waitingTime);
            clearInterval(timeInterval);
            return;
          }

          clearInterval(timeInterval);
        }
      }

      if (count >= 15) {
        clearInterval(timeInterval);
      }
    }, 500);
  };

  useEffect(() => {
    checkPopUpVariant();
  }, []);

  useEffect(() => {
    updateDisplayPopUpContext(showPopUp);
  }, [showPopUp]);

  useEffect(() => {
    if (isPopUpOpen && registrationFlowEvents.contactData) {
      setIsPopUpOpen(false);
    }
  }, [isPopUpOpen]);

  return (
    <Box
      tag="footer"
      padding={{ xs: '8x', lg: '12x' }}
      backgroundColor="black.default"
      color="white.default"
      ref={ref}
    >
      {!hideFooterNavigation && <FooterNavigation />}
      <FooterBottom />
      {isPopUpOpen && typeof document !== 'undefined' && createPortal(
        <ApplyPopup
          setIsPopUpOpen={setIsPopUpOpen}
          isPopUpOpen={isPopUpOpen}
        />, document.getElementById('modal-root'),
      )}
    </Box>
  );
};

Footer.defaultProps = {
  hideFooterNavigation: false,
};

Footer.propTypes = {
  hideFooterNavigation: PropTypes.bool,
};

export default Footer;
