import React from 'react';
import Collapse from '@nubank/nuds-web/components/Collapse/Collapse';
import Box from '@nubank/nuds-web/components/Box/Box';
import Link from '@nubank/nuds-web/components/Link/Link';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Grid from '@nubank/nuds-web/components/Grid/Grid';

import StyledCollapse from '../styles/StyledCollapse';

function ProductLinks() {
  return (
    <Grid.Child>
      <StyledCollapse id="products" initiallyOpen>
        <Collapse.Header>
          <Typography
            color="white.default"
            variant="subtitle1"
            strong
          >
            Qué productos ofrece Nu
          </Typography>
        </Collapse.Header>

        <Collapse.Body>
          <Box
            display="flex"
            flexDirection="column"
            marginTop="4x"
          >
            <Link
              color="white"
              typographyProps={{ variant: 'paragraph1', strong: true, marginBottom: '2x' }}
              href="/credito"
            >
              Tarjeta de Crédito sin anualidad
            </Link>

            <Link
              color="white"
              typographyProps={{ variant: 'paragraph1', strong: true, marginBottom: '2x' }}
              href="/cuenta"
            >
              Cuenta Nu: Tarjeta de débito y
              ahorros con rendimiento
            </Link>
            <Link
              color="white"
              typographyProps={{ variant: 'paragraph1', strong: true, marginBottom: '8x' }}
              href="/tarjeta-garantizada"
            >
              Tarjeta Garantizada Nu
            </Link>
            <Link
              color="white"
              typographyProps={{ variant: 'paragraph1', strong: true, marginBottom: '8x' }}
              href="/prestamo-personal"
            >
              Préstamos Personales Nu
            </Link>
          </Box>

        </Collapse.Body>
      </StyledCollapse>
    </Grid.Child>
  );
}

export default ProductLinks;
