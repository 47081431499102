import styled, { css } from 'styled-components';
import Link from '@nubank/nuds-web/components/Link/Link';
import { breakpointsMedia } from '@nubank/nuds-web/styles/breakpoints';

const SectionLinkWrapper = styled(Link)`
  ${breakpointsMedia({
    xs: css`
      display: flex;
      align-items: center;
      font-size: 1.875rem;
    `,
    md: css`
      font-size: 3rem;
    `,
    lg: css`
      font-size: 1rem;
    `,
  })}

  &:hover {
    text-decoration: none;
    background: none;
  }

  &:focus {
    text-decoration: none;
    background: none;
  }
`;

SectionLinkWrapper.defaultProps = {
  typographyProps: {
    variant: 'heading2',
    color: 'black',
  },
};

SectionLinkWrapper.displayName = 'SectionLinkWrapper';

export default SectionLinkWrapper;
