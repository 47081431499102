import { sendEvent } from '@nubank/www-latam-commons/services/analytics';

export const HEADER_EVENTS_CATEGORY = 'header';
export const FOOTER_EVENTS_CATEGORY = 'footer';
export const HOME_EVENTS_CATEGORY = 'home';
export const REGISTRATION_EVENTS_CATEGORY = 'card request';

export const registerHomeEvent = payload => {
  sendEvent('HOME_EVENTS', {
    ...payload,
    eventCategory: HOME_EVENTS_CATEGORY,
  });
};

export const registerFooterEvent = payload => {
  sendEvent('FOOTER_EVENTS', {
    ...payload,
    eventCategory: FOOTER_EVENTS_CATEGORY,
  });
};

export const registerHeaderEvent = payload => {
  sendEvent('HEADER_EVENTS', {
    ...payload,
    eventCategory: HEADER_EVENTS_CATEGORY,
  });
};
