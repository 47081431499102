import * as Sentry from '@sentry/react';

export const ERROR_SEVERITY = {
  DEFAULT: 'Error',
  CRITICAL: 'Critical',
  LOG: 'Log',
};

export function sentryException({
  error,
  flow,
  checkpoint,
  namespace,
  level = ERROR_SEVERITY.DEFAULT,
}) {
  return (
    Sentry.captureException(error, scope => {
      scope.setTag('flow', flow);
      scope.setTag('checkpoint', checkpoint);
      scope.setTag('namespace', namespace);
      scope.setLevel(level);
      scope.setFingerprint([
        flow,
        checkpoint,
        namespace,
        process.env.NODE_ENV,
        error?.message,
      ]);
    })
  );
}
