import React from 'react';
import Collapse from '@nubank/nuds-web/components/Collapse/Collapse';
import Box from '@nubank/nuds-web/components/Box/Box';
import Link from '@nubank/nuds-web/components/Link/Link';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Grid from '@nubank/nuds-web/components/Grid/Grid';

import StyledCollapse from '../styles/StyledCollapse';

function KnowMoreLinks() {
  return (
    <Grid.Child>
      <StyledCollapse id="know-more" initiallyOpen>
        <Collapse.Header>
          <Typography
            color="white.default"
            variant="subtitle1"
            strong
          >
            Conoce más de Nu México
          </Typography>
        </Collapse.Header>

        <Collapse.Body>
          <Box
            display="flex"
            flexDirection="column"
            marginTop="4x"
          >
            <Link
              color="white"
              typographyProps={{ variant: 'paragraph1', strong: true, marginBottom: '2x' }}
              href="https://international.nubank.com.br/es/sobre-nu/"
            >
              Sobre Nu
            </Link>
            <Link
              color="white"
              typographyProps={{ variant: 'paragraph1', strong: true, marginBottom: '2x' }}
              href="https://blog.nu.com.mx/"
              variant="action"
              width="fit-content"
            >
              Blog
            </Link>
            <Link
              color="white"
              typographyProps={{ variant: 'paragraph1', strong: true, marginBottom: '2x' }}
              href="https://international.nubank.com.br/es/newsroom/"
            >
              Prensa
            </Link>
            <Link
              color="white"
              typographyProps={{ variant: 'paragraph1', strong: true, marginBottom: '2x' }}
              href="https://international.nubank.com.br/es/carreras/"
              variant="action"
              width="fit-content"
            >
              Trabaja en Nu
            </Link>
            <Link
              color="white"
              typographyProps={{ variant: 'paragraph1', strong: true, marginBottom: '8x' }}
              href="https://comunidad.nu.com.mx/"
            >
              Conoce otros clientes en
              <p>
                Comunidad Nu
              </p>

            </Link>
          </Box>
        </Collapse.Body>
      </StyledCollapse>
    </Grid.Child>
  );
}

export default KnowMoreLinks;
