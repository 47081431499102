import Collapse from '@nubank/nuds-web/components/Collapse/Collapse';
import styled, { css } from 'styled-components';
import { breakpointsMedia } from '@nubank/nuds-web/styles/breakpoints';

const StyledCollapse = styled(Collapse)`
  border-top: none;

  button {
    border-bottom: 1px solid #6A6A6A;

    & div {
      display: block;
      ${breakpointsMedia({ lg: css`display: none;` })}

      & path {
        fill: white;
      }
    }
  }
`;

StyledCollapse.displayName = 'StyledCollapse';

export default StyledCollapse;
