import styled, { css } from 'styled-components';
import Box from '@nubank/nuds-web/components/Box/Box';
import { spacing, nuDSColor } from '@nubank/nuds-web/styles/themeUtils';

const NavigationListSmallScreenWrapper = styled(Box)`
  width: 100vw;
  height: calc(100vh);
  padding-bottom: ${spacing('20x')};
  overflow: scroll;
  scroll-behavior: smooth;
  ${({ $isHidden }) => ($isHidden && css`transform: translate3d(100vw, 0, 0);`)}
  transition: transform 0.4s ease-in-out;
  will-change: transform;
  background-color: ${nuDSColor('white')};
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

NavigationListSmallScreenWrapper.displayName = 'NavigationListSmallScreenWrapper';

export default NavigationListSmallScreenWrapper;
