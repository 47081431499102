import styled from 'styled-components';
import Collapse from '@nubank/nuds-web/components/Collapse/Collapse';

const CollapseWrapper = styled(Collapse)`
  border: 0;

  & button:focus {
    outline: 2px solid black;
    outline-offset: 2px;
  }

  button {
    padding: 0;
  }
`;

CollapseWrapper.displayName = 'CollapseWrapper';

export default CollapseWrapper;
