import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { breakpointsMedia, useBreakpointsMedia, useBreakpointsMediaUp } from '@nubank/nuds-web/styles/breakpoints';
import Button from '@nubank/nuds-web/components/Button/Button';
import styled, { css } from 'styled-components';

import useWindowSize from '@nubank/www-latam-commons/utils/hooks/useWindowSize';

import { useSiteContext } from '../SiteContext/SiteContext';
import { registerHeaderEvent } from '../../utils/gtm';

// Scroll to top of the page and focus the email input
function openIosKeyboard() {
  const input = document.createElement('input');
  input.setAttribute('type', 'text');
  input.setAttribute('style', 'position: fixed; top: -100px; left: -100px;');
  document.body.appendChild(input);
  input.focus();
  // it's safe to remove the fake input after a 30s timeout
  setTimeout(() => {
    document.body.removeChild(input);
  }, 3000);
}

let position = 0;
const scrollToTop = () => {
  window.scrollTo({
    top: position,
    behavior: 'smooth',
  });
};

// Display the CTA button only when the user scrolls down
const SCROLL_FOLD_HEIGHT_DESKTOP = 768;
const SCROLL_FOLD_HEIGHT_MOBILE = 530;
const SCROLL_FOLD_HEIGHT_TABLET = 920;

const findFoldHeight = (width, breakpoints) => {
  if (width >= breakpoints.md && width < breakpoints.lg) {
    return SCROLL_FOLD_HEIGHT_TABLET;
  }

  if (width >= breakpoints.lg) {
    return SCROLL_FOLD_HEIGHT_DESKTOP;
  }

  return SCROLL_FOLD_HEIGHT_MOBILE;
};

const CustomCTA = styled(Button)`
  &&& {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    margin-bottom: 1rem;
    position: fixed;
    bottom: 0;
    width: calc(100vw - 3rem);
    left: 0;

    ${breakpointsMedia({
    xs: css`
      box-shadow: 1px 4px 16px -6px rgb(0 0 0 / 50%);
    `,
    md: css`
        position: initial;
        width: auto;
        margin-bottom: 0;
        margin-left: 0;
        margin-right: 0;
        box-shadow: none;
        height: 2.5rem;
      `,
  })}

    ${({ isHidden }) => (isHidden ? 'display: none;' : '')}
    ${({ background }) => (background ? `background-color: ${background};` : '')}
    ${({ gradient }) => (gradient ? `background: ${gradient};` : '')}
    ${({ color }) => (color ? `color: ${color};` : '')}
  }
`;

export const HeaderCTA = ({
  isHidden, label, btnVisibleScrolling,
}) => {
  const {
    openRegistrationForm, isFormBoxAvailable, formPosition, focusEmailInputFormBox,
  } = useSiteContext();
  // Scroll to top of the page and focus the email input
  position = useBreakpointsMediaUp('lg') ? 0 : (formPosition - 80);

  const scrollToForm = () => {
    scrollToTop();
    openIosKeyboard();
    focusEmailInputFormBox();
  };

  // Display the CTA button only when the user scrolls down
  const [isInFold, setIsInFold] = useState(true);
  const { width } = useWindowSize();
  const breakpoints = useBreakpointsMedia();

  function handleScrollPosition() {
    const yPosition = window.scrollY;
    const foldHeight = findFoldHeight(width, breakpoints);
    const isInFoldRange = yPosition <= foldHeight;
    setIsInFold(isInFoldRange);
  }

  useEffect(() => {
    if (btnVisibleScrolling) {
      handleScrollPosition();
      window.addEventListener('scroll', handleScrollPosition, { passive: true });
      return () => {
        window.removeEventListener('scroll', handleScrollPosition);
      };
    }
    return setIsInFold(false);
  }, []);

  const handleOnClick = () => {
    registerHeaderEvent({
      eventAction: 'button click - default CTA',
      eventLabel: 'CTA - open registration form',
    });

    if (isFormBoxAvailable) {
      scrollToForm();
    } else {
      openRegistrationForm();
    }
  };

  if (isHidden) {
    return null;
  }

  return (
    <CustomCTA
      extended
      isHidden={!isHidden && isInFold}
      variant="contained"
      onClick={handleOnClick}
      intlKey={label}
      gradient="linear-gradient(99.92deg, #9F2DEB 18.57%, #6200A3 71.77%),linear-gradient(0deg, #2DB965, #2DB965)"
    />
  );
};

HeaderCTA.defaultProps = {
  isHidden: false,
};

HeaderCTA.propTypes = {
  btnVisibleScrolling: PropTypes.bool.isRequired,
  isHidden: PropTypes.bool,
  label: PropTypes.string.isRequired,
};

