import React from 'react';
import Box from '@nubank/nuds-web/components/Box/Box';
import Grid from '@nubank/nuds-web/components/Grid/Grid';

import ContactAndHelpLinks from './ContactAndHelpLinks';
import ProductLinks from './ProductLinks';
import TermsAndConditionsLinks from './TermsAndConditionsLinks';
import KnowMoreLinks from './KnowMoreLinks';
import TransparencyLink from './TransparencyLink';
import CountryLinks from './CountryLinks';
import BenefitsLinks from './BenefitsLinks';
import BackupLinks from './BackupLinks';
import PrivacyPolicyLinks from './PrivacyPolicyLinks';

const FooterNavigation = () => (
  <Box marginBottom={{ xs: '6x', lg: '12x' }}>
    <Grid
      gridTemplateColumns={{ xs: '1fr', lg: '1fr 1fr 1fr 1fr 1fr' }}
      paddingLeft="0"
      paddingRight="0"
    >
      <ContactAndHelpLinks />
      <ProductLinks />
      <TermsAndConditionsLinks />
      <KnowMoreLinks />
      <TransparencyLink />
      <CountryLinks />
      <BenefitsLinks />
      <BackupLinks />
      <PrivacyPolicyLinks />
    </Grid>

  </Box>
);

export default FooterNavigation;
