import React from 'react';
import Grid from '@nubank/nuds-web/components/Grid/Grid';
import Collapse from '@nubank/nuds-web/components/Collapse/Collapse';
import Box from '@nubank/nuds-web/components/Box/Box';
import Link from '@nubank/nuds-web/components/Link/Link';
import Typography from '@nubank/nuds-web/components/Typography/Typography';

import StyledCollapse from '../styles/StyledCollapse';

function BenefitsLinks() {
  return (
    <Grid.Child>
      <StyledCollapse id="benefits" initiallyOpen>
        <Collapse.Header>
          <Typography
            color="white.default"
            variant="subtitle1"
            strong
          >
            Beneficios
          </Typography>
        </Collapse.Header>

        <Collapse.Body>
          <Box
            display="flex"
            flexDirection="column"
            marginTop="4x"
          >
            <Link
              color="white"
              typographyProps={{ variant: 'paragraph1', strong: true, marginBottom: '2x' }}
              href="/meses-sin-intereses-con-nu"
            >
              Meses Sin Intereses y Aliados
            </Link>
            <Link
              color="white"
              typographyProps={{ variant: 'paragraph1', strong: true, marginBottom: '8x' }}
              href="/promociones"
            >
              Promociones
            </Link>
          </Box>
        </Collapse.Body>
      </StyledCollapse>
    </Grid.Child>
  );
}

export default BenefitsLinks;
