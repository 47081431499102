export const domain = `https://${process.env.NEXT_PUBLIC_WWW_DOMAIN}`;

export const images = [
  {
    url: `${domain}/images/seo/open-graph-logo-large.jpg?v=2`,
    width: 1200,
    height: 630,
  },
  {
    url: `${domain}/images/seo/open-graph-logo.png?v=2`,
    width: 300,
    height: 300,
  },
];

export const baseBreadcrumb = [{
  position: 1,
  name: 'Inicio',
  item: `${domain}/`,
}];

export const corporateContactData = {
  url: domain,
  logo: `${domain}/images/seo/nu-icon.png?v=2`,
  contactPoint: [{
    telephone: '+52 55 9225 2622',
    contactType: 'customer service',
    contactOption: 'TollFree',
    areaServed: 'MX',
    availableLanguage: ['Spanish'],
  }],
};
