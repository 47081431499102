import React from 'react';
import Grid from '@nubank/nuds-web/components/Grid/Grid';
import Collapse from '@nubank/nuds-web/components/Collapse/Collapse';
import Box from '@nubank/nuds-web/components/Box/Box';
import Link from '@nubank/nuds-web/components/Link/Link';
import Typography from '@nubank/nuds-web/components/Typography/Typography';

import StyledCollapse from '../styles/StyledCollapse';

function CountryLinks() {
  return (
    <Grid.Child>
      <StyledCollapse id="countries" initiallyOpen>
        <Collapse.Header>
          <Typography
            color="white.default"
            variant="subtitle1"
            strong
          >
            Nu en otros países
          </Typography>
        </Collapse.Header>

        <Collapse.Body>
          <Box
            display="flex"
            flexDirection="column"
            marginTop="4x"
          >
            <Link
              color="white"
              typographyProps={{ variant: 'paragraph1', strong: true, marginBottom: '2x' }}
              href="https://nubank.com.br/"
              variant="action"
              width="fit-content"
            >
              Nu Brasil
            </Link>
            <Link
              color="white"
              typographyProps={{ variant: 'paragraph1', strong: true, marginBottom: '8x' }}
              href="https://nu.com.co/"
              variant="action"
              width="fit-content"
            >
              Nu Colombia
            </Link>
          </Box>
        </Collapse.Body>
      </StyledCollapse>
    </Grid.Child>
  );
}

export default CountryLinks;
