import React from 'react';
import Grid from '@nubank/nuds-web/components/Grid/Grid';
import Collapse from '@nubank/nuds-web/components/Collapse/Collapse';
import Box from '@nubank/nuds-web/components/Box/Box';
import Link from '@nubank/nuds-web/components/Link/Link';
import Typography from '@nubank/nuds-web/components/Typography/Typography';

import StyledCollapse from '../styles/StyledCollapse';

function TransparencyLink() {
  return (
    <Grid.Child>
      <StyledCollapse id="transparency" initiallyOpen>
        <Collapse.Header>
          <Typography
            color="white.default"
            variant="subtitle1"
            strong
          >
            Transparencia
          </Typography>
        </Collapse.Header>

        <Collapse.Body>
          <Box
            display="flex"
            flexDirection="column"
            marginTop="4x"
          >
            <Link
              color="white"
              typographyProps={{ variant: 'paragraph1', strong: true, marginBottom: '2x' }}
              href="/fondo-de-proteccion-al-ahorro"
            >
              Fondo de Protección al Ahorro
            </Link>
            <Link
              color="white"
              typographyProps={{ variant: 'paragraph1', strong: true, marginBottom: '2x' }}
              href="/informacion-financiera"
            >
              Información Financiera
            </Link>
            <Link
              color="white"
              typographyProps={{ variant: 'paragraph1', strong: true, marginBottom: '2x' }}
              href="/consultas-y-aclaraciones-spei"
            >
              Consultas y Aclaraciones SPEI
            </Link>
            <Link
              color="white"
              typographyProps={{ variant: 'paragraph1', strong: true, marginBottom: '2x' }}
              href="/etica-y-compliance"
            >
              Ética y Compliance
            </Link>
            <Link
              color="white"
              typographyProps={{ variant: 'paragraph1', strong: true, marginBottom: '2x' }}
              href="/sistema-de-remuneraciones"
            >
              Sistema de Remuneraciones
            </Link>
            <Link
              color="white"
              typographyProps={{ variant: 'paragraph1', strong: true, marginBottom: '2x' }}
              href="/despachos-de-cobranza"
            >
              Cobranza
            </Link>
            <Link
              color="white"
              typographyProps={{ variant: 'paragraph1', strong: true, marginBottom: '2x' }}
              href="https://esg-journey.nuinternational.com/"
            >
              Nu Impacto
            </Link>
            <Link
              color="white"
              typographyProps={{ variant: 'paragraph1', strong: true, marginBottom: '8x' }}
              href="https://www.investidores.nu/en/"
            >
              Inversionistas
            </Link>
          </Box>
        </Collapse.Body>
      </StyledCollapse>
    </Grid.Child>
  );
}

export default TransparencyLink;
