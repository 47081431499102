import React from 'react';
import Box from '@nubank/nuds-web/components/Box/Box';
import Link from '@nubank/nuds-web/components/Link/Link';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import styled from 'styled-components';

const StyledContainerBox = styled(Box)`
  display: grid;
  gap: 32px;
  grid-template-columns: 1fr;

  @media (width >= 768px) {
    grid-template-columns: 1fr 214px auto;

    a {
      justify-self: end;
    }
  }
`;

const FooterInfo = () => (
  <StyledContainerBox
    marginTop={{ xs: '16px', lg: '60px' }}
    width="100%"
  >
    <Typography
      variant="paragraph1"
      maxWidth={{ xs: '100%', md: '100%' }}
    >
      Nu México Financiera S.A. de C.V. S.F.P.
      Manuel Avila Camacho 201 Piso 9,
      Col. Polanco I Sección, CP 11510,
      Alcaldía Miguel Hidalgo, Ciudad de México.
    </Typography>

    <Link
      href="https://cdn.nubank.com.br/MX/costos-y-comisiones.pdf"
      color="white"
      typographyProps={{ variant: 'paragraph2' }}
    >
      Consulta costos y comisiones de
      Nuestros Productos
    </Link>

    <Link
      href="/contratos"
      color="white"
      typographyProps={{ variant: 'paragraph2' }}
    >
      Contratos y Formatos
      Legales
    </Link>
  </StyledContainerBox>
);

export default FooterInfo;
