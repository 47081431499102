/* eslint-disable indent */
import styled, { css } from 'styled-components';
import { breakpointsMedia } from '@nubank/nuds-web/styles/breakpoints';

import NavLogo from './NavLogo/NavLogo';

export const logoHeight = {
  tablet: '40px',
  desktop: '45px',
};

export const SiteNavLogo = styled(NavLogo)`
  box-shadow: none;
  position: relative;

  ${breakpointsMedia({
    md: css`
      z-index: 1;
    `,
  })}

  svg {
    height: ${logoHeight.tablet};
    width: ${logoHeight.tablet};

    ${breakpointsMedia({
      lg: css`
        height: ${logoHeight.desktop};
        width: ${logoHeight.desktop};
      `,
    })}
  }
`;

export const HeaderContainer = styled.header`
  display: flex;
  align-items: center;
  position: relative;
  transition: transform .2s;
  ${({ isHidden }) => isHidden && css`transform: translateY(-100%);`}
`;
