import React from 'react';
import Grid from '@nubank/nuds-web/components/Grid/Grid';
import Collapse from '@nubank/nuds-web/components/Collapse/Collapse';
import Box from '@nubank/nuds-web/components/Box/Box';
import Link from '@nubank/nuds-web/components/Link/Link';
import Typography from '@nubank/nuds-web/components/Typography/Typography';

import StyledCollapse from '../styles/StyledCollapse';

function BackupLinks() {
  return (
    <Grid.Child>
      <StyledCollapse id="backup" initiallyOpen>
        <Collapse.Header>
          <Typography
            color="white.default"
            variant="subtitle1"
            strong
          >
            Conoce quién respalda a Nu
          </Typography>
        </Collapse.Header>

        <Collapse.Body>
          <Box
            display="flex"
            flexDirection="column"
            marginTop="4x"
          >
            <Link
              color="white"
              typographyProps={{ variant: 'paragraph1', strong: true, marginBottom: '2x' }}
              href="https://www.gob.mx/cnbv"
              variant="action"
              width="fit-content"
            >
              Comisión Nacional Bancaria y
              de Valores (CNBV)
            </Link>
            <Link
              color="white"
              typographyProps={{ variant: 'paragraph1', strong: true, marginBottom: '2x' }}
              href="/condusef"
            >
              Condusef
            </Link>
            <Link
              color="white"
              typographyProps={{ variant: 'paragraph1', strong: true, marginBottom: '8x' }}
              href="/buro-de-entidades-financieras"
            >
              Buró de Entidades Financieras
            </Link>
          </Box>
        </Collapse.Body>
      </StyledCollapse>
    </Grid.Child>
  );
}

export default BackupLinks;
