import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import HeaderWrapper from './HeaderWrapper';
import { HeaderContainer } from './HeaderContainer';

const Header = styled(HeaderWrapper)`
  z-index: 99;
`;

function HeaderXP({
  isHidden,
  children,
}) {
  return (
    <Header>
      <HeaderContainer isHidden={isHidden}>
        {children}
      </HeaderContainer>
    </Header>
  );
}

HeaderXP.defaultProps = {
  children: undefined,
  isHidden: false,
};

HeaderXP.propTypes = {
  children: PropTypes.node,
  isHidden: PropTypes.bool,
};

export default HeaderXP;
