import React from 'react';
import Box from '@nubank/nuds-web/components/Box/Box';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import NuLogo from '@nubank/nuds-web/components/NuLogo/NuLogo';
import styled from 'styled-components';

import FooterSocial from './FooterSocial';

const StyledContainerBox = styled(Box)`
  gap: 18px;
`;

const StyledBox = styled(Box)`
  gap: 16px;
  max-width: 600px;
`;

const FooterLogo = () => (
  <StyledContainerBox display="flex" flexDirection={{ xs: 'column', lg: 'row' }}>
    <div>
      <NuLogo variant="white" />
    </div>

    <StyledBox display="flex" flexDirection={{ xs: 'column' }}>
      <Typography
        variant="paragraph1"
        strong
      >
        © 2024 Nu México
      </Typography>

      <Typography variant="paragraph1" strong>
        Nu México Financiera S.A. de C.V., SFP (Nu México)
        es una entidad financiera autorizada y supervisada
        por la Comisión Nacional Bancaria y de Valores y
        demás autoridades financieras, bajo la Ley de Ahorro y Crédito Popular
      </Typography>
    </StyledBox>

    <FooterSocial />
  </StyledContainerBox>
);

export default FooterLogo;
